import React, { useState } from 'react';

import { CopyLinkWithButton } from './CopyLinkWithButton';
import ReactModal from '@/components/atoms/react-modal.component';
import CloseIcon from '@mui/icons-material/Close';
import { useGetCompany } from '@/hooks';
import { useInviteLink } from '@/hooks/useInviteLink';

export const ActivityLibraryModal = ({
    imageSrc,
    showModal,
}: {
    imageSrc: string;
    showModal: boolean;
}) => {
    const [isOpen, setIsOpen] = useState(true);
    const { company } = useGetCompany();
    const { inviteUrl } = useInviteLink();

    // do not show anything until company data become available
    if (!company?.createDate || !showModal) {
        return null;
    }

    return (
        <ReactModal
            show={isOpen}
            setShow={setIsOpen}
            contentStyle={{ backgroundColor: '#AB59FC', maxWidth: '34rem' }}
        >
            <div className="flex justify-end pb-2">
                <CloseIcon
                    className="cursor-pointer"
                    onClick={() => setIsOpen(false)}
                    style={{
                        fill: '#5509ae',
                    }}
                />
            </div>
            <div className="text-white text-center pb-2">
                <h1 className="text-2xl">
                    {"You're Ready to Take Off with Bonda!"}
                </h1>
                <div>
                    <iframe
                        src="https://player.vimeo.com/video/854491980?h=1596f4c1d4&autoplay=1&portrait=0&byline=0&pip=0title=0&sidedock=0"
                        style={{
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '310px',
                        }}
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>

                <p>
                    {
                        "Let's gather the crew! Invite your team to join your company account on Bonda using the link below, or check out our library and launch an activity!"
                    }
                </p>
                <br />
                <CopyLinkWithButton valueToCopy={inviteUrl} />
            </div>
        </ReactModal>
    );
};

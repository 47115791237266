import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

export const CopyLinkWithButton = ({
    valueToCopy,
}: {
    valueToCopy: string;
}) => {
    const [copied, setCopied] = useState(false);
    return (
        <div className="w-full">
            <input
                className="h-[2rem] px-4 rounded-md w-[20rem]"
                value={valueToCopy}
                disabled
            />

            <CopyToClipboard text={valueToCopy} onCopy={() => setCopied(true)}>
                <button className="text-white h-[2rem] ml-[-12px] text-sm rounded-md  px-4 w-[6rem] bg-purple hover:bg-purple-200">
                    {!copied ? 'Copy link' : 'Copied!'}
                </button>
            </CopyToClipboard>
        </div>
    );
};

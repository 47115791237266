import { authFetch } from '@/utils/authFetch';
import { observer } from 'mobx-react-lite';
import Link from 'next/link';
import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { SideMenuLayout } from '../components/layouts/side-menu-layout';

import { useAuth } from '../utils/auth';
import { CompanyType, LastGamesType, TeamType } from '@/utils/apiTypes';
import { ActivityType } from '@/utils/eventTypes';
import { TRUTHS_AND_LIES } from '@/utils/activityList';
import ReactModal from '@/components/atoms/react-modal.component';
import { subStatusCompare } from '@/utils/subscriptionUtils';
import { useRouter } from 'next/router';
import { ActivityLibraryModal } from '@/components/common/ActivityLibraryModal';
import { Box, Modal } from '@mui/material';

// export async function getStaticProps() {
//     // const google_client_id = process.env.GOOGLE_CLIENT_ID;
//     const { data } = await authFetch('', `/web/game`);
//     return {
//         props: {
//             // google_client_id,
//             activities: data.sort(
//                 (a, b) => Number(b.available) - Number(a.available)
//             ),
//         },
//     };
// }

const meetingTypes = [
    'Brainstorming Session',
    'Strategy Session',
    'Stand-Up Meeting',
    'Onboarding Meeting',
    'Team Building Meeting',
    'Other',
];

export const SelectTeam = observer(
    ({
        teamsList,
        onSelect,
    }: {
        teamsList: TeamType[];
        onSelect: (id: string, meetingType: string) => void;
    }) => {
        const [selected, setSelected] = useState(false);
        const { register, handleSubmit } = useForm();
        const onSubmit = async (data) => {
            setSelected(true);
            onSelect(data.teamId, data.meetingType);
        };
        const showTeamSelector = teamsList.length > 1;

        return (
            <Modal open disableAutoFocus>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 420,
                        height: 'fit-content',
                        bgcolor: 'white',
                        p: 4,
                        outline: 'none',
                        borderRadius: '6px',
                    }}
                >
                    <div className="flex flex-col items-center justify-center text-center">
                        <div>
                            <span className="flex w-full text-xl font-bold">{`I'm launching this activity to play with`}</span>

                            <form
                                className="flex flex-col py-2 space-y-1"
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <div className="flex justify-center items-center gap-1">
                                    <span
                                        className={`${
                                            showTeamSelector && 'hidden'
                                        } text-purple text-xl font-bold`}
                                    >
                                        {teamsList[0]?.name}
                                    </span>
                                    <select
                                        className={`${
                                            showTeamSelector
                                                ? 'block'
                                                : 'hidden'
                                        } flex-grow rounded-lg relative border border-gray-400 focus-visible:border-2 focus-visible:border-light-purple focus-visible:outline-0 focus-visible:ring-0`}
                                        placeholder="Select team"
                                        defaultValue={
                                            !showTeamSelector
                                                ? teamsList[0]?._id
                                                : ''
                                        }
                                        required
                                        {...register('teamId', {
                                            required: true,
                                        })}
                                    >
                                        <option value="" disabled hidden>
                                            Select team
                                        </option>
                                        {teamsList.map((team) => (
                                            <option
                                                key={team._id}
                                                value={team._id}
                                            >
                                                {team.name}
                                            </option>
                                        ))}
                                    </select>
                                    <span className="text-xl font-bold">
                                        team
                                    </span>
                                </div>

                                <div className="flex justify-center items-center gap-1">
                                    <span className="text-xl font-bold">
                                        during our
                                    </span>
                                    <select
                                        className="rounded-lg relative border border-gray-400 focus-visible:border-2 focus-visible:border-light-purple focus-visible:outline-0 focus-visible:ring-0"
                                        placeholder="Select meeting type"
                                        required
                                        defaultValue=""
                                        {...register('meetingType', {
                                            required: true,
                                        })}
                                    >
                                        <option value="" disabled hidden>
                                            Select meeting type
                                        </option>
                                        {meetingTypes.map((meating) => (
                                            <option
                                                key={meating}
                                                value={meating}
                                            >
                                                {meating}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <button
                                    type="submit"
                                    className="bg-purple text-white px-8 py-2 rounded-md"
                                >
                                    {selected ? 'Launching...' : 'Start game'}
                                </button>
                            </form>
                        </div>

                        <Link
                            href={
                                teamsList.length
                                    ? '/teams'
                                    : 'registration/team-creation'
                            }
                            passHref
                        >
                            <a
                                className={
                                    (!teamsList.length
                                        ? 'bg-purple text-white'
                                        : 'bg-white text-purple') +
                                    ' px-8 py-2 rounded-md hover:underline'
                                }
                            >
                                Team not listed? Join or create a team!
                            </a>
                        </Link>
                    </div>
                </Box>
            </Modal>
        );
    }
);

let showConfirmationModal: () => Promise<any> = async () => {
    throw false;
};

const NewGameModal = () => {
    const [showModal, setShowModal] = useState(false);
    const modalCallback = useRef<(yes: boolean) => void>();
    const show = () =>
        new Promise<boolean>((resolve, reject) => {
            modalCallback.current = (rslt) => (rslt ? resolve(rslt) : reject());
            setShowModal(true);
        });
    showConfirmationModal = show; // pass the function up kinda like "props.showRef.current = show"

    const respond = (rslt: boolean) => {
        setShowModal(false);
        modalCallback.current?.(rslt);
    };
    return (
        <ReactModal
            overlayStyle={{ zIndex: 51 }}
            show={showModal}
            setShow={(vis) => {
                setShowModal(vis);
                !vis && respond(false);
            }}
        >
            {/* ReactModal renders only once globally and overwrites its contents on each render, so no two ReactModals can exist on the page at once*/}
            <div className="w-[320px]">
                <div className="text-center">
                    <p className="font-bold">
                        Are you sure you want to start a new game?
                    </p>
                    <p>
                        Your previous game will be finished without saving
                        results.
                    </p>
                </div>

                <div className="flex justify-between mt-4">
                    <button onClick={() => respond(true)}>Yes</button>
                    <button onClick={() => respond(false)}>No</button>
                </div>
            </div>
        </ReactModal>
    );
};

export default observer(function Home() {
    const { authToken, setTeamId, companyId, setCompanyId, signOut } =
        useAuth();
    const [userData, setUserData] = useState(null);
    const [activityName, setActivityName] = useState('');
    const [showTeamSelector, setShowTeamSelector] = useState(false);
    const [launching, setLaunching] = useState(false);
    const [companyInfo, setCompanyInfo] = useState<CompanyType | null>(null);
    const [lastGames, setLastGames] = useState<LastGamesType[]>([]);
    const [activities, setActivities] = useState<ActivityType[]>([]);

    useEffect(() => {
        authFetch<ActivityType[]>('', `/web/game`).then((response) =>
            setActivities(
                response.data.sort(
                    (a, b) => Number(b.available) - Number(a.available)
                )
            )
        );

        if (!companyId) return;
        authFetch(authToken, `/web/company/${companyId}`).then((response) =>
            setCompanyInfo(response.data.company)
        );
        // .catch((e) => console.error(e));
        authFetch(authToken, `/web/play/lastgame/${companyId}`).then(
            (response) => setLastGames(response.data)
        );
    }, [companyId, authToken, userData?.userDetail?.email]);

    useEffect(() => {
        authFetch(authToken, `/web/user`)
            .then((response) => {
                if (response.status === false) {
                    signOut();
                    return;
                }
                if (response.data?.userDetail?.firstVisit) {
                    authFetch(authToken, `/web/user/firstvisit`);
                }
                setUserData(response.data);
            })
            .catch((err) => {
                if (err === 'User not found.') {
                    signOut();
                }
            });
    }, [authToken]);

    // Initiate a socket connection and set up game session-related data
    const createGame = async (
        teamId: string,
        activityName: string,
        meetingType: string
    ) => {
        console.log('activityId', activityName);
        console.log('teamId', teamId);

        const response = await authFetch(authToken, `/web/play`, 'POST', {
            teamid: teamId,
            companyid: companyId,
            // activityId will define the type of the game to be played
            // Create default activity if current type not yet supported
            activityId: activityName || TRUTHS_AND_LIES,
            meetingType,
        });

        if (response?.status === true) {
            console.log(response?.data);
            setTeamId(response?.data?.getTeam._id);
            setCompanyId(response?.data?.getCompany._id);
            setLaunching(false);
            setShowTeamSelector(false);
            setTimeout(
                () =>
                    window.open(
                        `/activities/${response?.data?.gamesessionId}`,
                        '_blank'
                    ),
                0
            );
        }
    };

    const onTeamSelected = (
        id: string,
        activityName: string,
        meetingType: string
    ) => {
        setTeamId(id);
        // setBelongsToTeam(true);
        try {
            createGame(id, activityName, meetingType);
        } catch (e) {
            console.error(e);
        }
    };

    const companyHasTrialOrSub = subStatusCompare(
        companyInfo?.subscription?.status,
        ['active', 'trialing']
    );

    const userIsActive = userData?.userDetail?.active;

    const buttonText = (
        continueLast: boolean,
        available: boolean,
        actName,
        activityName,
        launching: boolean
    ) => {
        if (!available) return 'Coming Soon';
        if ((companyInfo && !companyHasTrialOrSub) || !userIsActive)
            return 'Purchase Seats';
        if (launching && actName === activityName) return 'Launching...';
        if (continueLast) return 'Continue last game';
        if (userData?.teamDetails.length === 0) return 'Join or Create Team';
        return 'Launch Activity';
    };

    const LaunchButton = ({ activity }: { activity: ActivityType }) => {
        const findLastGame = (lastGames: LastGamesType[]) =>
            lastGames.find((a) => a.activityId === activity.name)
                ?.lastPendingActivity;
        const weHaveTheLastGame = findLastGame(lastGames);
        const router = useRouter();

        const continueToTeamSelection = async (confirmed: boolean) => {
            if (!userData) return;

            // if (!userHasTrialOrSub) {
            //     stripeCheckoutSubmit(authToken, '/activities', );
            //     return;
            // }
            if (!confirmed) {
                // check if the user doesn't have an active game, otherwise ask if they actually want to
                const { data: activities = [] } = await authFetch<
                    LastGamesType[]
                >(authToken, `/web/play/lastgame/${companyId}`);
                if (findLastGame(activities)) {
                    setLastGames(activities);
                    await showConfirmationModal(); // throws
                }
            }
            setActivityName(activity.name);
            // don't show the team selector if the user only has one team
            setShowTeamSelector(true);
        };
        return (
            <>
                <button
                    disabled={!activity.available}
                    className={`text-white px-2 py-2 rounded-md w-[140px] bg-purple hover:bg-light-purple disabled:opacity-50 disabled:hover:bg-purple`}
                    onClick={async () => {
                        if (!userIsActive) {
                            router.push('/subscription');
                            return;
                        }
                        if (userData.teamDetails.length === 0) {
                            router.push('/teams');
                            return;
                        }
                        setLaunching(true);
                        setActivityName(activity.name);
                        if (companyInfo && !companyHasTrialOrSub) {
                            router.push('subscription');
                            return;
                        }
                        if (weHaveTheLastGame) {
                            // immediately continue last game
                            window.open(
                                `/activities/${weHaveTheLastGame?._id}`,
                                '_blank'
                            );
                            setLaunching(false);
                            return;
                        }
                        // window.open(
                        //     `/activities/${getActivity?.lastPendingActivity._id}`,
                        //     '_blank'
                        // );
                        try {
                            await continueToTeamSelection(false); // throws
                        } catch {
                            setLaunching(false);
                        }
                    }}
                >
                    {buttonText(
                        !!weHaveTheLastGame,
                        activity.available,
                        activity.name,
                        activityName,
                        launching
                    )}
                </button>

                <a
                    className={`px-2 py-2 rounded-md w-[140px] bg-inherit flex justify-center text-gray-500 hover:text-purple hover:underline cursor-pointer ${
                        !weHaveTheLastGame && 'invisible'
                    }`}
                    onClick={async () => {
                        try {
                            setActivityName(activity.name);
                            setLaunching(true);
                            await showConfirmationModal(); // throws
                            await continueToTeamSelection(true); // throws
                        } catch {
                            setLaunching(false);
                        }
                    }}
                >
                    {companyHasTrialOrSub && <div>Launch new game</div>}
                </a>
            </>
        );
    };

    return (
        <SideMenuLayout user={userData}>
            <div className="flex justify-between items-end w-full">
                <h1 className="h1">Activity Library</h1>
            </div>
            {activityName && showTeamSelector && (
                <SelectTeam
                    teamsList={userData?.teamDetails || []}
                    onSelect={(teamId, meetingType) =>
                        onTeamSelected(teamId, activityName, meetingType)
                    }
                />
            )}

            <ActivityLibraryModal
                imageSrc="/activity-modal-first-time.png"
                showModal={userData?.userDetail?.firstVisit}
            />

            <NewGameModal />
            {companyInfo ? (
                <div
                    className={
                        companyHasTrialOrSub ? 'opacity-100' : 'opacity-50'
                    }
                >
                    {/* table with list of teams created and their information*/}
                    <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                                >
                                                    Thumbnail
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 max-w-[500px]"
                                                >
                                                    Activity Description
                                                </th>
                                                {/* <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Objectives
                      </th> */}
                                                <th
                                                    scope="col"
                                                    className="px-4 py-4 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Values Strengthened
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                ></th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {activities?.map((activity) => (
                                                <tr key={activity._id}>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                        <img
                                                            src={
                                                                activity?.image
                                                            }
                                                            className="bg-gray-200 w-16 h-16"
                                                        />
                                                    </td>
                                                    <td className="flex-wrap px-3 py-4 text-sm text-gray-500">
                                                        <div className="font-medium text-gray-900 max-w-[500px] py-3">
                                                            <p className="font-black mb-2">
                                                                {activity.title}
                                                            </p>
                                                            <p>
                                                                {
                                                                    activity?.description
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="whitespace-nowrap flex justify-start gap-x-4">
                                                            <p>
                                                                {activity?.time}{' '}
                                                                minutes
                                                            </p>
                                                            <p>
                                                                max{' '}
                                                                {
                                                                    activity?.maxPeople
                                                                }{' '}
                                                                people
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        {activity.focusAreas.map(
                                                            (value) => (
                                                                <p
                                                                    className="py-2 px-3 rounded-full bg-gray-200 m-1 w-fit text-xs"
                                                                    key={value}
                                                                >
                                                                    {value}
                                                                </p>
                                                            )
                                                        )}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        <div className="flex flex-col">
                                                            <LaunchButton
                                                                activity={
                                                                    activity
                                                                }
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div role="status" className="w-full mt-12">
                    <div className="h-12 bg-gray-200 rounded-lg dark:bg-gray-100 w-full mb-2.5"></div>
                    {[0, 1, 2, 3].map((v) => (
                        <div
                            key={v}
                            className="h-44 bg-gray-200 rounded-lg dark:bg-gray-100 w-full mb-2.5"
                        ></div>
                    ))}
                </div>
            )}
        </SideMenuLayout>
    );
});

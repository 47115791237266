import { useEffect, useState } from 'react';
import { useGetCompany } from './useGetCompany';

export const useInviteLink = () => {
    const { company } = useGetCompany();
    const [inviteUrl, setInviteUrl] = useState<string | null>(null);

    useEffect(() => {
        const newUrl = new URL(`${window.location.origin}/login`);
        newUrl.searchParams.append('companyName', company?.name);
        newUrl.searchParams.append('companyId', company?.id);
        newUrl.searchParams.append('inviteBy', company?.userName);
        setInviteUrl(newUrl.toString());
    }, [company?.id, company?.name, company?.userName]);

    return { inviteUrl };
};

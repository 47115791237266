import React from 'react';
import Modal from 'react-modal';

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('body');

type ModalProps = {
    show: boolean;
    setShow: (show: boolean) => void;
    children?: React.ReactNode;
    overlayStyle?: React.CSSProperties;
    contentStyle?: React.CSSProperties;
};

const defaultContentStyle: React.CSSProperties = {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '15px',
};

const ReactModal = ({
    show,
    setShow,
    overlayStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        backdropFilter: 'blur(4px)',
    },
    contentStyle,
    children,
}: ModalProps) => {
    return (
        <div>
            <Modal
                isOpen={show}
                onRequestClose={() => setShow(false)}
                style={{
                    content: { ...defaultContentStyle, ...contentStyle },
                    overlay: overlayStyle,
                }}
                contentLabel="Example Modal"
            >
                {children}
            </Modal>
        </div>
    );
};

export default ReactModal;
